import React from 'react';
import ReactDOM from 'react-dom';
import { EnvironmentVarsStore } from '@wings-shared/env-store';
import { environmentVars } from './environment.vars';
import { ConfigureOkta } from '@wings/shared';
import App from './app';

// Store Env Variables
new Promise<void>((resolve, reject) => {
  const environmentStore = new EnvironmentVarsStore();
  environmentStore.setVars(environmentVars);
  ConfigureOkta.start()
  ReactDOM.render(<App />, document.getElementById('root'));
  resolve();
});
