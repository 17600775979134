import { ENVIRONMENT_VARS } from '@wings-shared/env-store';

const ENV = ENVIRONMENT_VARS;

export const environmentVars: Record<ENVIRONMENT_VARS, string> = {
  [ENV.SKIP_PREFLIGHT_CHECK]: process.env.SKIP_PREFLIGHT_CHECK || '',
  [ENV.AZURE_INSTRUMENTATION_KEY]: process.env.REACT_APP_AZURE_INSTRUMENTATION_KEY || '',
  [ENV.AIRPORT_LOGISTICS_SUBSCRIPTION_KEY]: process.env.REACT_APP_AIRPORT_LOGISTICS_SUBSCRIPTION_KEY || '',
  [ENV.OKTA_URL]: process.env.REACT_APP_OKTA_URL || '',
  [ENV.OKTA_CLIENT_ID]: process.env.REACT_APP_OKTA_CLIENT_ID || '',
  [ENV.OKTA_AUTHORIZATION_SERVER_ID]: process.env.REACT_APP_OKTA_AUTHORIZATION_SERVER_ID || '',
  [ENV.AIRPORT_LOGISTICS_API]: process.env.REACT_APP_AIRPORT_LOGISTICS_API || '',
  [ENV.HOST_ENVIRONMENT]: process.env.REACT_APP_HOST_ENVIRONMENT || '',
  [ENV.CLIENT_ID]: process.env.REACT_APP_CLIENT_ID || '',
  [ENV.OCP_APIM_SUBSCRIPTION_KEY]: process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY || '',
  [ENV.REFERENCE_DATA_BASE_API]: process.env.REACT_APP_REFERENCE_DATA_BASE_API || '',
  [ENV.USER_MANAGEMENT_BASE_API]: process.env.REACT_APP_USER_MANAGEMENT_BASE_API || '',
  [ENV.HIDDEN_MODULES]: process.env.REACT_APP_HIDDEN_MODULES || '',
  [ENV.AG_GRID_LICENSE_KEY]: process.env.REACT_APP_AG_GRID_LICENSE_KEY || '',
  [ENV.UVGO_WEBSITE]: process.env.REACT_APP_UVGO_WEBSITE || '',
  [ENV.EVENTS_SUBSCRIPTION_KEY]: process.env.REACT_APP_EVENTS_SUBSCRIPTION_KEY || '',
  [ENV.EVENTS_BASE_API]: process.env.REACT_APP_EVENTS_BASE_API || '',
  [ENV.PUBLIC_BASE_API]: process.env.REACT_APP_PUBLIC_BASE_API || '',
  [ENV.UVGO_ALLOW_ASSUMED_IDENTITY_GROUP_ID]: process.env.REACT_APP_UVGO_ALLOW_ASSUMED_IDENTITY_GROUP_ID || '',
  [ENV.PUSH_NOTIFICATIONS_URL]: process.env.REACT_APP_PUSH_NOTIFICATIONS_URL || '',
  [ENV.FUEL_BASE_URL]: process.env.REACT_APP_FUEL_BASE_URL || '',
  [ENV.FLIGHT_PLANNING_BASE_URL]: process.env.REACT_APP_FLIGHT_PLANNING_BASE_URL || '',
  [ENV.FLIGHT_PLANNING_SUBSCRIPTION_KEY]: process.env.REACT_APP_FLIGHT_PLANNING_SUBSCRIPTION_KEY || '',
  [ENV.MAPBOX_API_TOKEN]: process.env.REACT_APP_MAPBOX_API_TOKEN || '',
  [ENV.UWA_AD_PROVIDER]: process.env.REACT_APP_UWA_AD_PROVIDER || '',
  [ENV.USER_MANAGEMENT_SUBSCRIPTION_KEY]: process.env.REACT_APP_USER_MANAGEMENT_SUBSCRIPTION_KEY || '',
  [ENV.ADMIN_PORTAL_BASE_API]: '' || '', // missing: ADMIN_PORTAL_BASE_API
  [ENV.WINGS_BASE_URL]: '' || '', // missing: ADMIN_PORTAL_BASE_API,
  [ENV.VENDOR_MANAGEMENT_CORE_API]: process.env.REACT_APP_VENDOR_MANAGEMENT_CORE_API || '',
  [ENV.VENDOR_MANAGEMENT_NOSQL_API]: process.env.REACT_APP_VENDOR_MANAGEMENT_NOSQL_API || '',
  [ENV.VENDOR_MANAGEMENT_SUBSCRIPTION_KEY]: process.env.REACT_APP_VENDOR_MANAGEMENT_SUBSCRIPTION_KEY || '',
  [ENV.AUTHORIZATION_BASE_API]: process.env.REACT_APP_AUTHORIZATION_BASE_API || '',
  [ENV.WINGS_GROUP_ID]: process.env.REACT_APP_WINGS_GROUP_ID || '',
  [ENV.UVGO_GROUP_ID]: process.env.REACT_APP_UVGO_GROUP_ID || '',
  [ENV.SM_GROUP_ID]: process.env.REACT_APP_SM_GROUP_ID || '',
  [ENV.UPLINK_UI_GROUP_ID]: process.env.REACT_APP_UPLINK_UI_GROUP_ID || '',
  [ENV.UVGO_APPLICATION_ID]: process.env.REACT_APP_UVGO_APPLICATION_ID || '',
  [ENV.UVX_APP_SERVICE_ID]: process.env.REACT_APP_UVX_APP_SERVICE_ID || '',
};
